import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row g-5 g-xl-8" }
const _hoisted_2 = { class: "row g-5 g-xl-8" }
const _hoisted_3 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_4 = { class: "card-header border-0 pt-5" }
const _hoisted_5 = { class: "card-title align-items-start flex-column" }
const _hoisted_6 = { class: "card-label fw-bolder text-dark" }
const _hoisted_7 = { class: "card-body pt-5" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "symbol symbol-40px me-1" }
const _hoisted_10 = { class: "text-dark fw-bold" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "d-flex flex-column" }
const _hoisted_13 = {
  class: "text-dark text-hover-primary fs-6 fw-bolder m-0 p-0",
  style: {"cursor":"pointer"}
}
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "d-flex my-3 ms-9" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  title: "",
  "data-bs-original-title": "Edit"
}
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CreateMinatBakatComponent = _resolveComponent("CreateMinatBakatComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-lg btn-primary me-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModalAdd(false, {})))
      }, "Buat Soal")
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.minatBakatCache, (item2, index2) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index2,
          class: "col-xl-4"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(item2.TitleGroup), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.items, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "row"
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass([{ 'mb-7': item2.items.length - 1 !== index }, "d-flex align-items-center"])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("span", {
                          class: _normalizeClass([`bg-light-${item.Color}`, "symbol-label"])
                        }, [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(item.QuestionNumber), 1)
                        ], 2)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", _hoisted_13, _toDisplayString(item.QuestionDescription), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", {
                          onClick: ($event: any) => (_ctx.onUpdate(item)),
                          class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                          "data-bs-toggle": "tooltip",
                          title: "",
                          "data-kt-customer-payment-method": "update",
                          "data-bs-original-title": "Update"
                        }, [
                          _createElementVNode("span", _hoisted_17, [
                            _createElementVNode("span", _hoisted_18, [
                              _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                            ])
                          ])
                        ], 8, _hoisted_16),
                        _createElementVNode("p", {
                          onClick: ($event: any) => (_ctx.onDelete(item)),
                          class: "btn btn-icon btn-active-light-primary w-30px h-30px me-3",
                          "data-bs-toggle": "tooltip",
                          title: "",
                          "data-kt-customer-payment-method": "delete",
                          "data-bs-original-title": "Delete"
                        }, [
                          _createElementVNode("span", _hoisted_20, [
                            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen027.svg" })
                          ])
                        ], 8, _hoisted_19)
                      ])
                    ])
                  ], 2)
                ]))
              }), 128))
            ])
          ])
        ]))
      }), 128))
    ]),
    _createVNode(_component_CreateMinatBakatComponent, {
      tipeTestId: _ctx.TypeTestID,
      isopenmodal: _ctx.isopenmodal,
      onModalClose: _ctx.isModalClose,
      isupdate: _ctx.isUpdate,
      itemsupdate: _ctx.itemsUpdate,
      onIsneedrefresh: _ctx.isNeedRefresh
    }, null, 8, ["tipeTestId", "isopenmodal", "onModalClose", "isupdate", "itemsupdate", "onIsneedrefresh"])
  ], 64))
}