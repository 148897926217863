
import { defineComponent, ref, toRefs, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { string } from "yup/lib/locale";

interface MinatBakat {  
  QuestionID: number
  QuestionNumber: number
  QuestionDescription: string
  TypeTestID: string
  IsExample: number
  Times: number
  TitleGroup: string
}

export default defineComponent({
  name: "create-minat-bakat-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    isopenmodal:Boolean,
    isupdate: Boolean,
    itemsupdate: {
        type: Object,
        default: () => ({}),
    },
    tipeTestId: String,
  },
  setup(props, {emit}) {
    const { isopenmodal, itemsupdate } = toRefs(props);
    let dialogAddGroup = ref(false)
    const submitButton = ref<HTMLElement | null>(null);
    var minatBakat = ref<MinatBakat>({
                QuestionID: 0,
                QuestionNumber: 0,
                QuestionDescription: "",
                TypeTestID: props.tipeTestId || '',
                IsExample: 0,
                Times: 0,
                TitleGroup: "",
              })

    function resetDataMinatBakat(){
        minatBakat.value.QuestionID = 0
        minatBakat.value.QuestionNumber = 0
        minatBakat.value.QuestionDescription= ""
        minatBakat.value.TypeTestID	= props.tipeTestId || ''
        minatBakat.value.IsExample	=  0
        minatBakat.value.Times = 0
        minatBakat.value.TitleGroup= ""
    }
    
    watch(isopenmodal, (newVal, oldVal) => {
      resetDataMinatBakat()

      if(props.isupdate){
        minatBakat.value.QuestionID = props.itemsupdate.QuestionID
        minatBakat.value.QuestionNumber = props.itemsupdate.QuestionNumber
        minatBakat.value.QuestionDescription= props.itemsupdate.QuestionDescription
        minatBakat.value.TypeTestID	=  props.itemsupdate.TypeTestID
        minatBakat.value.IsExample	=  props.itemsupdate.IsExample
        minatBakat.value.Times = props.itemsupdate.Times
        minatBakat.value.TitleGroup= props.itemsupdate.TitleGroup
      }

      if(newVal){
        dialogAddGroup.value = true
      }
    });

    function modalClose(){
      dialogAddGroup.value = false
      emit("modalClose", true);
    }

    const minatBakatValidator = Yup.object().shape({
      fno: Yup.string().required().label("No."),
      fname: Yup.string().required().label("Pertanyaan"),
      fgroup: Yup.string().required().label("Group"),
    });

    function saveForm (data_save){
      var saveObj = {}
      saveObj = Object.assign({}, data_save._rawValue)

      if(props.isupdate){
        update(saveObj)
      }else{
        save(saveObj)
      }
    };

    async function save(saveObj){
      var responseCache = []
      await Services.PostData(ApiService2, `master/questions-minat-bakat/`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    async function update(saveObj){
      var responseCache = []
      await Services.PutData(ApiService2,  `master/questions-minat-bakat/${saveObj.QuestionID}`, saveObj, response=>{
        responseCache = response.data
        toast("Save Berhasil!", 
        {
          autoClose: 1000,
          type: 'success',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        emit("isneedrefresh", 1);
        cancelModal()
      }, err =>{
        err
        toast("Save Gagal", 
        {
          autoClose: 1000,
          type: 'error',
          pauseOnFocusLoss: false,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        cancelModal()
      })
    }

    const saveMinatBakat = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
        saveForm(minatBakat)
      }
    };

    function cancelModal(){
      resetDataMinatBakat()
      modalClose()
    }

    return {
      // data,
      minatBakat,
      minatBakatValidator,
      saveMinatBakat,
      submitButton,
      dialogAddGroup,
      cancelModal,
      modalClose,
    };
  },
});
