
import { defineComponent, onMounted, ref } from "vue";
import CreateMinatBakatComponent from "@/views/psy/minat-bakat/component/CreateMinatBakatComponent.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Services from "@/core/services/ArrService";
import ApiService2 from "@/core/services/ApiService2";

export default defineComponent({
  name: "minat-bakat-adm",
  components: {
    CreateMinatBakatComponent,
  },
  setup() {
    let isopenmodal = ref(false)
    var isUpdate = ref(false)
    var itemsUpdate = ref({})
    var minatBakatCache = ref([] as Array<any>)
    var minatBakatExampleCache = ref([] as Array<any>)
    const TypeTestID = process.env.VUE_APP_TYPE_TEST_ID_MINAT_BAKAT

    onMounted(() => {
      setCurrentPageBreadcrumbs("MINAT BAKAT", []);
      getMinatBakat()
    });

    function openModalAdd(isupdate, itemsupdate){
      isUpdate.value = isupdate
      itemsUpdate.value = itemsupdate
      isopenmodal.value = true
    }

    function isModalClose(val) {
      isopenmodal.value = false
    }

    function isNeedRefresh(val) {
      getMinatBakat()
    }

    function getRandomColor() {
      var colorList = ["success", "warning", "primary", "danger", "info"];

      return colorList[Math.floor(Math.random() * 5)]
    }

    async function getMinatBakat(){
      minatBakatCache.value = []
      minatBakatExampleCache.value = []
      await Services.GetData(ApiService2, `master/questions-minat-bakat/masterid`, TypeTestID, response=>{
        for (var i = 0; i < response.data.length; i++) {
          response.data[i].Color = getRandomColor()
          response.data[i].Numb = Number(response.data[i].QuestionNumber)
          if(response.data[i]['IsExample'] == 1){           
            minatBakatExampleCache.value.push(response.data[i])
          }else{
            minatBakatCache.value.push(response.data[i])
          }
        }

        minatBakatCache.value = minatBakatCache.value.orderBy(["Numb"], "ASC")
        minatBakatCache.value = minatBakatCache.value.groupBy(["TitleGroup"])
        // minatBakatCache.value = minatBakatCache.value.orderBy(["TitleGroup"], "ASC")
      }, err =>{
        err
      })
    }

    function onDelete(val){
      deleteMinatBakat(val.QuestionID)
    }

    async function deleteMinatBakat(QuestionID){
      await Services.DeleteData(ApiService2, `master/questions-minat-bakat/${QuestionID}`, response=>{
        getMinatBakat()
      }, err =>{
        err
      })
    }

    function onUpdate(val){
      openModalAdd(true, val)
    }

    return {
      isUpdate,
      itemsUpdate,
      isopenmodal,
      openModalAdd,
      isNeedRefresh,
      isModalClose,
      TypeTestID,
      minatBakatCache,
      onDelete,
      onUpdate,
    };
  },
});
